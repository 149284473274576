<template>
  <div class="outer-page">
    <div class="center-width">
      <div class="content">
        <ul>
          <li v-for="(item, index) in newsList" :key="index" class="news-list">
            <a v-if="index == 0">
              <div style="margin-bottom: 0.8rem">
                <img class="nn-img" :src="item.servicePath + item.cover" />
                <div class="nn-info">
                  <h6 @click="goThere(item)">
                    {{ item.name }}
                  </h6>
                  <span>{{ item.createDate }}</span>
                  <p></p>
                </div>
                <div class="clears"></div>
              </div>
            </a>
            <a class="nn-list" v-if="index !== 0">
              <ul>
                <li>
                  <a>
                    <p @click="goThere(item)">
                      {{ item.name }}
                    </p>
                    <span>{{ item.createDate }}</span>
                  </a>
                </li>
              </ul>
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="news-page">
      <Page
        :current="page.pageNo"
        :page-size="page.pageSize"
        :total="page.total"
        size="small"
        @on-change="pageChange"
      ></Page>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "",
  components: {},
  data() {
    return {
      newsList: [],
      //分页
      page: {
        pageNo: 1,
        pageSize: 15,
        total: 0,
      },
    };
  },
  computed: {
    getSubMenu() {
      return this.$store.state.permission.subMenu;
    },
    getCurrentRoute() {
      return this.$store.state.permission.currentRoute;
    },
  },
  methods: {
    goThere(item) {
      this.$router.push({
        name: "mnewsInfo",
        query: {
          id: item.id,
          key: this.$route.name,
        },
      });
    },
    getList() {
      if (Object.keys(this.getSubMenu).length == 0) return;
      let child = this.getSubMenu.childList.filter(
        (item) => item.router == this.getCurrentRoute
      )[0];
      if (child.Interface) {
        this.$post(child.Interface, {
          pageSize: this.page.pageSize,
          pageNo: this.page.pageNo,
        }).then((res) => {
          this.newsList = res.list;
          this.page.total = +res.total;
          document.body.scrollTop = document.documentElement.scrollTop = 0;
          for (let i = 0; i < this.newsList.length; i++) {
            this.newsList[i].createDate = moment(
              this.newsList[i].createDate
            ).format("YYYY-MM-DD");
          }
        });
      }
    },
    //分页
    pageChange(pageNo) {
      this.page.pageNo = pageNo;
      this.getList();
    },
  },
  mounted() {
    this.getList();
  },
  watch: {
    getSubMenu: {
      deep: true,
      handler() {
        this.getList();
      },
    },
    $route() {
      this.getList();
    },
  },
};
</script>

<style lang="less" scoped>
.outer-page {
  width: 100%;
  padding: 0.5rem 0;
  font-size: 0.577rem;
  line-height: 1rem;
  .center-width {
    width: 94%;
    margin: 0 3%;
    .content {
      font-size: 0.577rem;
      line-height: 0.88rem;
      color: #666666;
      //   padding-top: 0.5rem;
      ul {
        .news-list {
          // border: 1px solid red;
          a {
            .nn-img {
              display: block;
              float: left;
              width: 48%;
              height: 4.711rem;
            }
            .nn-info {
              float: right;
              width: 50%;
              height: 4.711rem;
              h6 {
                font-size: 0.622rem;
                color: #333333;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
              }
              span {
                color: #245f5c;
                font-family: Arial;
                font-size: 0.533rem;
              }
              p {
                color: #666;
                font-size: 0.488rem;
                line-height: 0.75rem;
                height: 3.05rem;
                overflow: hidden;
              }
            }
            .clears {
              clear: both;
              line-height: 0;
              overflow: hidden;
              font-size: 0;
              height: 0;
            }
          }
          .nn-list {
            ul {
              list-style: none;
              li {
                height: 1.555rem;
                line-height: 1.555rem;
                border-top: 1px #dddddd solid;
                a {
                  color: #333;
                  p {
                    display: inline-block;
                    width: 70%;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                    font-weight: bold;
                  }
                  span {
                    float: right;
                    font-size: 0.488rem;
                    color: #999999;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .news-page {
    text-align: center;
    margin-top: 0.7rem;
  }
}
</style>